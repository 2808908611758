import HomeCarousel from "../components/HomeCarousel";
import HeroSection from "../components/HeroSection";
import LoadingScreen from "../components/LoadingScreen";
import SubscribePopup from "../components/PopupFrom";
import "../styles.css";

export const Home = () => {
  return (
    <div>
       <LoadingScreen />
      <SubscribePopup />
      <HomeCarousel />
      <HeroSection />
    </div>
  );
};
